<template>
  <div>
    <h1 class="subtitle-1 grey--text">Категории программного обеспечения</h1>
    <SoftwareCategoryDialogCreate />

    <v-container class="mb-5">
      <SoftwareCategoryCard v-for="category in softwareCategory.categories" :key="category.id" :category="category" />
    </v-container>

    <template v-if="page != 1">
      <router-link :to="{ name: 'software-category-list', query: { page: page - 1 } }" rel="prev">Prev Page</router-link>
      <template v-if="hasNextPage"> | </template>
    </template>
    <router-link v-if="hasNextPage" :to="{ name: 'software-category-list', query: { page: page + 1 } }" rel="next"> Next Page</router-link>
  </div>
</template>

<script>
import SoftwareCategoryCard from '@/components/SoftwareCategoryCard'
import SoftwareCategoryDialogCreate from '@/components/SoftwareCategoryDialogCreate'
import { mapState } from 'vuex'
import store from '@/store/index'

function getPageCategories(routeTo, next) {
  const currentPage = parseInt(routeTo.query.page || 1)
  store
    .dispatch('softwareCategory/fetchPageCategories', {
      page: currentPage
    })
    .then(() => {
      routeTo.params.page = currentPage
      next()
    })
}

export default {
  props: {
    page: {
      type: Number,
      required: true
    }
  },
  components: {
    SoftwareCategoryCard,
    SoftwareCategoryDialogCreate
  },
  beforeRouteEnter(routeTo, routeFrom, next) {
    getPageCategories(routeTo, next)
  },
  beforeRouteUpdate(routeTo, routeFrom, next) {
    getPageCategories(routeTo, next)
  },
  computed: {
    hasNextPage() {
      return this.softwareCategory.categoriesTotal > this.page * this.perPage
    },
    ...mapState(['softwareCategory', 'user'])
  }
}
</script>
